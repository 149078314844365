import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Box } from '@mui/material';
import { motion } from 'framer-motion';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import { HOST_API } from '../config'; // Adjust the path as needed



function Register() {
  const navigate = useNavigate();

  const secretKey = CryptoJS.enc.Base64.parse(process.env.REACT_APP_SECRET_KEY);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
  });

  const encryptData = (data) => {
    const iv = CryptoJS.lib.WordArray.random(16); // Generate random IV
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    const encryptedData = iv.concat(encrypted.ciphertext); // Combine IV and encrypted text
    return CryptoJS.enc.Base64.stringify(encryptedData); // Convert to Base64
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const encryptedData = encryptData(formData); // Encrypt the form data

    try {
      const response = await axios.post(
        `${HOST_API}/register`, 
        { payload: encryptedData }, // Send encrypted data in JSON
        {
          headers: {
            'Content-Type': 'application/json', // Set content type to JSON
          },
        }
      );      console.log(response.data);
      alert('Registration Successful!');
      navigate('/login')
    } catch (error) {
      console.error('Error during registration:', error.response?.data);
      alert('Registration Failed');
    }
  };
  

  return (
    <Container maxWidth="sm" style={{ marginTop: '40px' }}>
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <Typography variant="h4" component="h1" color="primary" gutterBottom>
          Register
        </Typography>
      </motion.div>
      <motion.form
        onSubmit={handleSubmit}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3, duration: 0.8 }}
      >
        <TextField
          label="Name"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          variant="outlined"
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Email"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleInputChange}
          variant="outlined"
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Password"
          name="password"
          type="password"
          value={formData.password}
          onChange={handleInputChange}
          variant="outlined"
          fullWidth
          margin="normal"
          required
        />
        <Box mt={3}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            style={{ padding: '10px 0' }}
          >
            Register
          </Button>
        </Box>
      </motion.form>
    </Container>
  );
}

export default Register;
