import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, CircularProgress, Alert } from '@mui/material';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import AddPanForm from './AddPanForm'; // Import the AddPanForm component
import * as XLSX from 'xlsx';
import { useSnackbar } from 'notistack'; // Import notistack for notifications
import CryptoJS from 'crypto-js'; // Import CryptoJS
import { HOST_API } from '../config'; // Adjust the path as needed




function UploadFile({ onLogout }) {
  const [panList, setPanList] = useState([]); // Store the PAN list for the user
  const [selectedPANs, setSelectedPANs] = useState([]); // Track selected PANs for processing
  const [isLoading, setIsLoading] = useState(false); // Loading state for processing
  const [limit, setLimit] = useState(0); // Store the PAN limit
  const [remainingSlots, setRemainingSlots] = useState(0); // Store remaining slots
  const [loading, setLoading] = useState(true); // Loading state for fetching data
  const navigate = useNavigate();
  const token = localStorage.getItem('authToken'); // Retrieve JWT token
  const { enqueueSnackbar } = useSnackbar(); // Destructure enqueueSnackbar from useSnackbar


  const loaderStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  };

  const rotatingDots = {
    display: 'inline-block',
    position: 'relative',
    width: '80px',
    height: '80px',
  };

  const rotatingDot = (index) => ({
    position: 'absolute',
    width: '16px',
    height: '16px',
    backgroundColor: '#4caf50',
    borderRadius: '50%',
    animation: `rotating 1.2s linear infinite`,
    animationDelay: `${index * -0.1}s`,
  });

  const rotatingAnimation = `
  @keyframes rotating {
    0% { transform: scale(0); }
    100% { transform: scale(1); opacity: 0; }
  }
  `;

  useEffect(() => {
    fetchPanList(); // Fetch the PAN list immediately after login

    const tokenExpiration = localStorage.getItem('tokenExpiration');
    if (tokenExpiration && new Date().getTime() > tokenExpiration) {
      handleLogout();
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('authToken'); // Remove token
    localStorage.removeItem('tokenExpiration'); // Remove token expiration
    onLogout();
    navigate('/login');
  };


  const secretKey = CryptoJS.enc.Base64.parse(process.env.REACT_APP_SECRET_KEY);

  const encryptPayload = (data) => {
    const iv = CryptoJS.lib.WordArray.random(16); // Generate random IV
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    const encryptedData = iv.concat(encrypted.ciphertext); // Combine IV and encrypted text
    return CryptoJS.enc.Base64.stringify(encryptedData); // Convert to Base64
  };

  // Example usage in API call
  const handlePanDataSubmit = async (newPanData) => {
    try {
      const encryptedData = encryptPayload(newPanData);

      const response = await axios.post(
        `${HOST_API}/add-pan-bulk`,
        { payload: encryptedData },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      alert(response.data.message);
      fetchPanList();
    } catch (error) {
      console.error('Error adding PANs:', error);
      alert('Failed to add PANs. Please try again.');
    }
  };

  // AES Decryption function
  const decryptData = (encryptedData) => {
    // Decode the Base64-encoded response
    const encryptedBytes = CryptoJS.enc.Base64.parse(encryptedData);

    // Extract the first 16 bytes as the IV
    const iv = CryptoJS.lib.WordArray.create(encryptedBytes.words.slice(0, 4));

    // Extract the remaining bytes as the ciphertext
    const ciphertext = CryptoJS.lib.WordArray.create(encryptedBytes.words.slice(4));

    // Decrypt the ciphertext using the secret key and IV
    const decrypted = CryptoJS.AES.decrypt(
      { ciphertext: ciphertext },
      secretKey,
      { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
    );

    // Parse the decrypted data from UTF-8 string to JSON
    return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
  };

  // Fetch the list of PANs and remaining slots from the backend
  const fetchPanList = async () => {
    try {
      const response = await axios.get(`${HOST_API}/pan-list`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      // Decrypt the encrypted response data
      const decryptedData = decryptData(response.data.encryptedData);
      setPanList(decryptedData.pans || []);
      setLimit(decryptedData.limit || 0);
      setRemainingSlots(decryptedData.remaining_slots || 0);
      setLoading(false); // Stop loading
    } catch (error) {
      console.error('Error fetching PAN list:', error);
      setLoading(false); // Stop loading even if there's an error
    }
  };

  // Toggle checkbox selection for PANs
  const handleCheckboxChange = (pan) => {
    setSelectedPANs((prevSelected) =>
      prevSelected.includes(pan)
        ? prevSelected.filter((selected) => selected !== pan)
        : [...prevSelected, pan]
    );
  };

  const processPANsSequentially = async (selectedPANs, type) => {

    try {
      for (let pan of selectedPANs) {
        const formData = new FormData();

        // Prepare the data for the current PAN
        const panData = [
          {
            index: selectedPANs.indexOf(pan) + 1,
            pan: pan.pan,
            password: pan.password,
          },
        ];

        // Create an Excel sheet with a single PAN record
        const worksheet = XLSX.utils.json_to_sheet(panData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Selected_PAN');

        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        const blob = new Blob([excelBuffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });

        // Append the Excel file to FormData
        formData.append('file', blob, `${pan.pan}_selected.xlsx`);

        // Add the startIndex and endIndex (since it's a single PAN, both are the same)
        formData.append('startIndex', 1);
        formData.append('endIndex', 1);
        formData.append('type', type);  // Add the type parameter


        // Update the status to "Running" before sending the request
        setPanList((prevList) =>
          prevList.map((item) =>
            item.pan === pan.pan ? { ...item, status: 'Running' } : item
          )
        );

        const response = await axios.post(`${HOST_API}/process`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          },
        });

        const decryptedData = decryptData(response.data.payload);

        const { assessment_year,
          demand_amount,
          refund_status,
          refund_date, } = decryptedData.results[0];

        // Update the PAN list based on the process type
        setPanList((prevList) => {
          console.log('Updating PAN list...'); // Debugging log
        
          return prevList.map((item) => {
            console.log(`Checking item.pan: ${item.pan} against pan: ${pan}`); // Log comparison
        
            if (item.pan === pan.pan) {
              console.log(`Updating PAN ${pan} to status: Complete`); // Log update
        
              // Update based on the process type
              return {
                ...item,
                ...(type === 'demand' && {
                  assessment_year: assessment_year || 'No assessment year found',
                  demand_amount: demand_amount || 'No demand amount found',
                }),
                ...(type === 'refund' && {
                  refund_status: refund_status || 'No refund status found',
                  refund_date: refund_date || 'No refund date found',
                }),
                status: 'Complete', // Ensure the status is updated
              };
            }
        
            // Return unchanged item
            return item;
          });
        });
        
        console.log(`PAN ${pan.pan} processed successfully:`, decryptedData);
      }

      enqueueSnackbar('All selected PANs processed successfully!', {
        variant: 'success',
        autoHideDuration: 3000, // This controls how long the notification is shown (in milliseconds)
      });
    } catch (error) {
      console.error('Error processing PANs:', error);
      alert('An error occurred while processing the PANs.');
    }
  };

  const handleProcessSelected = (type) => {
    if (selectedPANs.length === 0) {
      alert('Please select at least one PAN to process.');
      return;
    }
    processPANsSequentially(selectedPANs, type);
  };


  // Function to download the current PAN list as an Excel file
  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(panList); // Convert PAN list to worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'PAN_List');
    // Generate and download the Excel file
    XLSX.writeFile(workbook, 'PAN_List.xlsx');
  };

  const renderMessage = () => {
    if (remainingSlots > 0) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: '800px', // Maximum width of the alert
            margin: '0 auto 20px auto', // Center horizontally and add bottom margin
          }}
        >
          <Alert severity="info" style={{ marginBottom: '20px' }}>
            {`You have stored ${panList.length} PAN(s). You can add ${remainingSlots} more PAN(s) (Limit: ${limit}).`}
          </Alert>
        </div>
      );
    } else {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: '800px', // Maximum width of the alert
            margin: '0 auto 20px auto', // Center horizontally and add bottom margin
          }}
        >
          <Alert severity="warning" style={{ marginBottom: '20px' }}>
            {`You have reached the limit of ${limit} PAN(s). No more PANs can be added.`}
          </Alert>
        </div>
      );
    }
  };


  if (loading) {
    return (
      <div style={loaderStyle}>
        <style>{rotatingAnimation}</style>
        <div style={rotatingDots}>
          {[...Array(3)].map((_, index) => (
            <div key={index} style={rotatingDot(index)} />
          ))}
        </div>
      </div>
    );
  }


  const getRowStyle = (status) => {
    switch (status) {
      case 'Running':
        return { backgroundColor: 'rgba(255, 235, 59, 0.8)' }; // Yellow for Running
      case 'Complete':
        return { backgroundColor: 'rgba(76, 175, 80, 0.9)', color: 'rgba(255, 255, 255, 1)' }; // Green for Complete, white text
      case 'Error':
        return { backgroundColor: 'rgba(244, 67, 54, 0.9)', color: 'rgba(255, 255, 255, 1)' }; // Red for Error, white text
      default:
        return {}; // Default styling for Pending
    }
  };

  return (
    <motion.div
      style={{ padding: '20px', textAlign: 'center' }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <h2>Add PAN Numbers</h2>

      {renderMessage()}

      {/* Add PAN Form (Manual or Excel) */}
      <AddPanForm onPanDataSubmit={handlePanDataSubmit} />

      <TableContainer component={Paper} style={{ marginTop: '20px', maxWidth: '80%', margin: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Select</TableCell>
              <TableCell>PAN Number</TableCell>
              <TableCell>Assessment Year</TableCell>
              <TableCell>Demand Amount</TableCell>
              <TableCell>Refund Status</TableCell>
              <TableCell>Refund Status Date</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {panList.map((row, index) => (
              <TableRow
                key={index}
                style={getRowStyle(row.status)} // Apply different colors based on the status
              >
                <TableCell>
                  <Checkbox
                    checked={selectedPANs.includes(row)}
                    onChange={() => handleCheckboxChange(row)}
                  />
                </TableCell>
                <TableCell>{row.pan}</TableCell>
                <TableCell>{row.assessment_year || 'N/A'}</TableCell>
                <TableCell>{row.demand_amount || 'N/A'}</TableCell>
                <TableCell>{row.refund_status || 'N/A'}</TableCell>
                <TableCell>{row.refund_date || 'N/A'}</TableCell>
                <TableCell>{row.status || 'Pending'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Button
        variant="contained"
        color="primary"
        onClick={() => handleProcessSelected('demand')}
        disabled={isLoading || selectedPANs.length === 0}
      >
        {isLoading ? 'Processing...' : 'Process Selected PANs for Demanded Amount'}
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleProcessSelected('refund')}
        disabled={isLoading || selectedPANs.length === 0}
        style={{ marginLeft: '10px' }}
      >
        {isLoading ? 'Processing...' : 'Process Selected PANs for Refund Status'}
      </Button>
      <br></br>
      <Button
        variant="contained"
        color="secondary"
        onClick={downloadExcel}
        style={{ marginTop: '20px', marginLeft: '10px' }}
      >
        Download PAN List as Excel
      </Button>
      <br></br>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleLogout}
        style={{ marginTop: '20px' }}
      >
        Logout
      </Button>
    </motion.div>
  );
}

export default UploadFile;
