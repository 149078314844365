import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import UploadFile from './components/UploadFile';
import Register from './components/Register';
import Login from './components/Login';
import PrivateRoute from './components/PrivateRoute';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Check if the user is authenticated by checking for a token
  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    setIsAuthenticated(false);
  };

  return (
    <Router>
      <Routes>
        {/* Redirect logged-in users away from the Login and Register pages */}
        <Route
          path="/login"
          element={
            isAuthenticated ? <Navigate to="/upload" /> : <Login onLogin={() => setIsAuthenticated(true)} />
          }
        />
        <Route
          path="/register"
          element={
            isAuthenticated ? <Navigate to="/upload" /> : <Register />
          }
        />

        {/* Protected route: only accessible if authenticated */}
        <Route
          path="/upload"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated}>
              <UploadFile onLogout={handleLogout} />
            </PrivateRoute>
          }
        />

        {/* Default route: redirect to login or upload depending on authentication state */}
        <Route path="*" element={<Navigate to={isAuthenticated ? "/upload" : "/login"} />} />
      </Routes>
    </Router>
  );
}

export default App;
