import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Container, Typography, Box, CircularProgress } from '@mui/material';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { HOST_API } from '../config'; // Adjust the path as needed


function Login({ onLogin }) {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false); // Loader state

  const navigate = useNavigate();

  const secretKey = CryptoJS.enc.Base64.parse(process.env.REACT_APP_SECRET_KEY);

  const encryptData = (data) => {
    const iv = CryptoJS.lib.WordArray.random(16); // Generate random IV
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    const encryptedData = iv.concat(encrypted.ciphertext); // Combine IV and encrypted text
    return CryptoJS.enc.Base64.stringify(encryptedData); // Convert to Base64
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading

    const encryptedData = encryptData(formData); // Encrypt the form data

    try {
      const response = await axios.post(`${HOST_API}/login`, { encryptedData }, {
        headers: { 'Content-Type': 'application/json' },
      });

      if (response.status === 200) {
        const token = response.data.token;
        const expirationTime = new Date().getTime() + 24 * 60 * 60 * 1000;

        localStorage.setItem('authToken', token);
        localStorage.setItem('tokenExpiration', expirationTime);

        onLogin();
        navigate('/upload');
      }
    } catch (error) {
      setError(error.response?.data?.error || 'An unexpected error occurred');
    } finally {
      setLoading(false); // Stop loading after response
    }
  };

  return (
    <Container maxWidth="sm" style={{ marginTop: '40px' }}>
      <Typography variant="h4" color="primary" gutterBottom>Login</Typography>
      {error && <Typography color="error" style={{ marginBottom: '20px' }}>{error}</Typography>}
      <form onSubmit={handleSubmit}>
        <TextField label="Email" name="email" type="email" value={formData.email} onChange={handleInputChange} variant="outlined" fullWidth margin="normal" disabled={loading} required />
        <TextField label="Password" name="password" type="password" value={formData.password} onChange={handleInputChange} variant="outlined" fullWidth margin="normal" disabled={loading} required />
        <Box mt={3} display="flex" justifyContent="center" alignItems="center">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            disabled={loading} // Disable button during loading
            startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null} // Show loader
          >
            {loading ? 'Logging in...' : 'Login'}
          </Button>
        </Box>
      </form>
    </Container>
  );
}

export default Login;
