import React from 'react';
import { Navigate } from 'react-router-dom';

function PrivateRoute({ children, isAuthenticated }) {
  const token = localStorage.getItem('authToken');

  // If the token is not available or the user is not authenticated, redirect to login
  return token ? children : <Navigate to="/login" />;
}

export default PrivateRoute;
