// AddPanForm.js
import React, { useState } from 'react';
import { Button, TextField } from '@mui/material';
import * as XLSX from 'xlsx';

function AddPanForm({ onPanDataSubmit }) {
  const [isManualEntry, setIsManualEntry] = useState(true); // Toggle between manual and Excel upload
  const [manualPan, setManualPan] = useState(''); // For manual PAN entry
  const [manualPassword, setManualPassword] = useState(''); // For manual password entry

  // Toggle between manual entry and Excel upload
  const toggleEntryMode = () => {
    setIsManualEntry((prev) => !prev);
  };

  // Handle manual PAN and password submission
  const handleManualSubmit = (e) => {
    e.preventDefault();

    const newPan = {
      pan: manualPan,
      password: manualPassword,
    };

    onPanDataSubmit([newPan]); // Pass new PAN data to parent component

    // Clear input fields
    setManualPan('');
    setManualPassword('');
  };

  // Handle file upload for PAN numbers via Excel
  const handleFileChange = (e) => {
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

      // Map Excel data into the desired structure
      const panData = sheet.map((row) => ({
        pan: row.pan || `PAN ${row.index}`,
        password: row.password || 'defaultPassword',
      }));

      onPanDataSubmit(panData); // Pass Excel data to parent component
    };
    reader.readAsArrayBuffer(file);
  };

  return (
    <div style={{ marginBottom: '20px' }}>
      <Button variant="contained" color="secondary" onClick={toggleEntryMode} style={{ marginBottom: '10px' }}>
        {isManualEntry ? 'Switch to Excel Upload' : 'Switch to Manual Entry'}
      </Button>

      {isManualEntry ? (
        <form onSubmit={handleManualSubmit}>
          <TextField
            label="PAN Number"
            variant="outlined"
            value={manualPan}
            onChange={(e) => setManualPan(e.target.value)}
            style={{ marginRight: '10px' }}
            required
          />
          <TextField
            label="Password"
            variant="outlined"
            type="password"
            value={manualPassword}
            onChange={(e) => setManualPassword(e.target.value)}
            required
          />
          <Button variant="contained" color="primary" type="submit" style={{ marginLeft: '10px' }}>
            Add PAN
          </Button>
        </form>
      ) : (
        <div>
          <input type="file" onChange={handleFileChange} accept=".xlsx" />
          <p style={{ marginTop: '10px', color: '#666' }}>
            Upload an Excel file containing PAN numbers and passwords.
          </p>
        </div>
      )}
    </div>
  );
}

export default AddPanForm;
